import Box, { Card, Content, Flex, Grid, Section } from 'components/box'
import Button from 'components/button'
import Input from 'components/input'
import React from 'react'
import Text, { Caps, Heading } from 'components/text'

const Subscribe = () => {
  const formProps = {
    action:
      'https://nelo.us7.list-manage.com/subscribe/post?u=5cb918082a39aef5a646dcf3a&amp;id=1c456f616d',
    name: 'mc-embedded-subscribe-form',
    method: 'post',
  }

  return (
    <Section id="subscription_form" bg="neutral-800" minHeight="0" mt={5}>
      <Content my={-5}>
        <Card
          borderRadius={2}
          bg="neutral-900"
          elevation={4}
          overflow="hidden"
          p={0}
        >
          <Grid bg="neutral-800" columns={[1, 2]} gridGap="1px">
            <Flex bg="neutral-900" p={[3, 4]} alignItems="center">
              <Box maxWidth={['wide', 'normal']} mx="auto">
                <Heading color="white" level={2}>
                  Join Beta
                </Heading>
                <Text as="p" color="neutral-200" fontSize={[5, 5, 4]} mt={2}>
                  Intent products are currently in active development. We'll let
                  you know as soon as a public beta is available.
                </Text>
                <Text
                  as="p"
                  color="neutral-200"
                  fontSize={[5, 5, 4]}
                  mb={2}
                  mt={3}
                >
                  Know someone who might find Intent products useful? Spread the
                  word!
                </Text>
              </Box>
            </Flex>
            <Flex
              as="form"
              bg="neutral-900"
              p={[3, 4]}
              alignItems="center"
              {...formProps}
            >
              <input
                type="hidden"
                name="b_5cb918082a39aef5a646dcf3a_1c456f616d"
                value=""
              />
              <Flex
                flexDirection="column"
                maxWidth={['wide', 'normal']}
                mx="auto"
                my={[2, 3]}
              >
                <Box mb={3}>
                  <Caps
                    as="label"
                    color="neutral-400"
                    fontSize={[7, 7, 6]}
                    htmlFor="FNAME"
                  >
                    Name
                  </Caps>
                  <Input
                    required
                    dark
                    type="text"
                    name="FNAME"
                    id="subscriber_name"
                    mt={1}
                    size="large"
                    width="100%"
                  />
                </Box>
                <Box>
                  <Caps
                    as="label"
                    color="neutral-400"
                    fontSize={[7, 7, 6]}
                    htmlFor="EMAIL"
                  >
                    E-mail
                  </Caps>
                  <Input
                    required
                    dark
                    type="email"
                    name="EMAIL"
                    mt={1}
                    size="large"
                    width="100%"
                  />
                </Box>
                <Button
                  type="submit"
                  name="subscribe"
                  value="Subscribe"
                  mb={[0, 3]}
                  mt={4}
                  size="large"
                >
                  Get early access!
                </Button>
              </Flex>
            </Flex>
          </Grid>
        </Card>
      </Content>
    </Section>
  )
}

export default Subscribe
