const toTransitionDeclaration = ({
  property = 'all',
  duration = '250ms',
  easing = 'ease',
  delay = '0ms',
}) => {
  return `${property} ${duration} ${easing} ${delay}`
}

export default transitions => {
  const transition =
    transitions instanceof Array
      ? transitions.map(toTransitionDeclaration).join(',')
      : toTransitionDeclaration(transitions)

  return transition
    ? {
        transition: transition,
        '@media screen and (prefersReducedMotion: reduce)': {
          transition: 'none',
        },
      }
    : null
}
