import { Caret, Circle, Square, Triangle } from 'components/icon'
import Box, { Flex } from 'components/box'
import Link from 'components/link'
import PropTypes from 'prop-types'
import React from 'react'
import transition from 'styles/transition'

const ProductLink = props => (
  <Box as="li" role="none">
    <Link
      color="text"
      display="flex"
      fontWeight="medium"
      justifyContent="flex-start"
      lineHeight={3}
      mx={[0, 0, 2]}
      px={3}
      py={['6px', '6px', 2]}
      role="menuitem"
      {...props}
    />
  </Box>
)

const Products = ({ isDisplayed, isOpen, handleToggle, ...props }) => (
  <Flex
    as="ul"
    borderBottom={[1, 0]}
    borderColor="neutral-100"
    justifyContent="flex-end"
    {...props}
  >
    <Link
      aria-haspopup="menu"
      aria-expanded={isOpen}
      as="button"
      display={['none', 'inline-flex', 'none']}
      fontWeight="medium"
      lineHeight={3}
      onClick={() => handleToggle(!isOpen)}
      px={3}
      py={2}
    >
      Products
      <Caret isOpen={isOpen} />
    </Link>
    <Flex
      as="ul"
      bg={['transparent', 'white', 'transparent']}
      borderRadius={[0, 1, 0]}
      boxShadow={[0, 4, 0]}
      display={isDisplayed ? 'flex' : ['flex', 'none', 'flex']}
      flexDirection={['column', 'column', 'row']}
      justifyContent="center"
      mt={[0, 1, 0]}
      opacity={isOpen ? 1 : [1, 0, 1]}
      pointerEvents={isOpen ? 'initial' : ['initial', 'none', 'initial']}
      position={['intitial', 'absolute', 'initial']}
      py={[2, 2, 0]}
      role="menu"
      style={{
        ...transition(
          isOpen ? [{ property: 'opacity' }, { property: 'transform' }] : []
        ),
        transformOrigin: 'top',
      }}
      transform={isOpen ? 'none' : ['none', 'translateY(-1rem)', 'none']}
      top={['auto', '100%', 'auto']}
    >
      <ProductLink hoverColor="blue" to="/launcher">
        <Square opacity={0.5} mr={3} />
        Launcher
      </ProductLink>
      <ProductLink hoverColor="purple" to="/browser">
        <Circle opacity={0.5} mr={3} />
        Browser
      </ProductLink>
      <ProductLink hoverColor="red" to="/timer">
        <Triangle opacity={0.5} mr={3} />
        Timer
      </ProductLink>
    </Flex>
  </Flex>
)

Products.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
}

export default Products
