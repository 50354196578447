import css from '@styled-system/css'
import Headroom from 'react-headroom'
import styled from 'styled-components'
import transition from 'styles/transition'

const Container = styled(Headroom).attrs({
  disableInlineStyles: true,
})(
  css({
    '.headroom': {
      background: 'rgba(255, 255, 255, 0.98)',
      boxShadow: 1,
      left: 0,
      right: 0,
      top: 0,
      zIndex: 2,
      transition: transition([
        {
          property: 'background-color',
        },
        {
          property: 'box-shadow',
        },
      ]),
    },
    '.headroom--unfixed': {
      background: 'none',
      boxShadow: 0,
      position: 'relative',
      transform: 'translateY(0)',
    },
    '.headroom--scrolled': {
      transition: 'transform 250ms ease-in-out',
    },
    '.headroom--unpinned': {
      position: 'fixed',
      transform: 'translateY(-100%)',
    },
    '.headroom--pinned': {
      position: 'fixed',
      transform: 'translateY(0%)',
    },
  })
)

export default Container
