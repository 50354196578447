import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'
import transition from 'styles/transition'
import variant from 'styles/variant'
import { colors } from 'styles/theme'
import { Link } from 'gatsby'
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
} from 'styled-system'
import { shadows } from 'styles/theme'

const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  compose(
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    system({ pointerEvents: true }),
    system({ transform: true }),
    typography
  )
)

const Content = styled(Box)``

Content.defaultProps = {
  maxWidth: [0, 1, 2, 3],
  width: '100%',
  mx: 'auto',
  px: [3, 4, 5],
}

const Flex = styled(Box)``

Flex.defaultProps = {
  display: 'flex',
  flexGrow: 1,
}

const Grid = styled(Box)(
  ({ columns }) =>
    columns &&
    css({
      gridTemplateColumns:
        columns instanceof Array
          ? columns.map(c => `repeat(${c}, 1fr)`)
          : `repeat(${columns}, 1fr)`,
    })
)

Grid.defaultProps = {
  display: 'grid',
}

Grid.propTypes = {
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
}

const Card = styled(Box)(({ elevation }) =>
  css({
    boxShadow: elevation,
  })
)

Card.propTypes = {
  elevation: PropTypes.oneOf(Object.keys(shadows).map(i => parseInt(i))),
}

Card.defaultProps = {
  bg: 'white',
  border: 1,
  borderColor: 'shadow-200',
  borderRadius: 1,
  elevation: 0,
}

const CardLink = styled(Card).attrs({
  as: Link,
})(
  css({
    '&:hover': {
      textDecoration: 'none',
      transform: 'translateY(-0.5%)',
    },
    '&:focus': {
      outline: 'none',
    },
    '&.focus-visible': {
      boxShadow: `0 0 0 2px ${colors['primary-200']}`,
    },
  }),
  transition([{ property: 'transform' }])
)

const Section = styled(Flex)(
  css({
    alignItems: 'center',
    position: 'relative',
  }),
  variant({
    hero: {
      true: {
        minHeight: '100vh',
        mt: '-3.5em',
      },
    },
  })
)

Section.defaultProps = {
  minHeight: '75vh',
}

export { Card, CardLink, Content, Flex, Grid, Section }
export default Box
