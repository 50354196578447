import { colors } from 'styles/theme'
import { flexbox, layout, space } from 'styled-system'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import transition from 'styles/transition'
import variant from 'styles/variant'

const Button = styled('button')(
  flexbox,
  layout,
  space,
  css({
    alignItems: 'center',
    bg: 'primary',
    border: 0,
    borderRadius: 2,
    boxSizing: 'border-box',
    color: 'white',
    cursor: 'pointer',
    fontSize: 5,
    fontWeight: 'medium',
    justifyContent: 'center',
    lineHeight: 0,
    outline: 'none',
    whiteSpace: 'nowrap',

    '&:disabled': { opacity: 0.5, pointerEvents: 'none' },
    '& + &': { m: 2 },
    '&:hover': { bg: 'primary-300', textDecoration: 'none' },
    '&.focus-visible': {
      boxShadow: `0 0 0 2px ${colors['primary-200']}`,
      outline: 'none',
    },
    '&:active': { bg: 'primary-500', transition: 'none' },
  }),
  transition([
    { property: 'background' },
    { property: 'box-shadow', duration: '125ms' },
    { property: 'color' },
  ]),
  variant({
    color: {
      neutral: {
        bg: 'neutral-800',
        '&:hover': { bg: 'neutral-900' },
        '&.focus-visible': {
          boxShadow: `0 0 0 0.125em ${colors['neutral-500']}`,
        },
        '&:active': { bg: 'neutral-1000' },
      },
      blue: {
        bg: 'blue-700',
        '&:hover': { bg: 'blue-600' },
        '&.focus-visible': { boxShadow: `0 0 0 0.125em ${colors['blue-200']}` },
        '&:active': { bg: 'blue-800' },
      },
      purple: {
        bg: 'purple-500',
        '&:hover': { bg: 'purple-400' },
        '&.focus-visible': {
          boxShadow: `0 0 0 0.125em ${colors['purple-200']}`,
        },
        '&:active': { bg: 'purple-600' },
      },
      red: {
        bg: 'red-500',
        '&:hover': { bg: 'red-400' },
        '&.focus-visible': { boxShadow: `0 0 0 0.125em ${colors['red-200']}` },
        '&:active': { bg: 'red-600' },
      },
    },
  }),
  variant({
    size: {
      small: {
        borderRadius: 1,
        fontSize: 6,
        px: 3,
        py: 2,
      },
      large: {
        borderRadius: 2,
        fontSize: [5, 5, 4],
        lineHeight: 1,
        px: 4,
        py: 3,
      },
    },
  }),
  variant({
    rounded: {
      true: {
        borderRadius: 6,
      },
    },
  }),
  variant({
    sharp: {
      true: {
        borderRadius: 0,
      },
    },
  })
)

Button.propTypes = {
  color: PropTypes.oneOf(['blue', 'purple', 'red']),
  size: PropTypes.oneOf(['small', 'large']),
  rounded: PropTypes.bool,
  sharp: PropTypes.bool,
}

Button.defaultProps = {
  display: 'inline-flex',
  px: 4,
  py: 3,
  role: 'button',
}

const SecondaryButton = styled(Button)(
  css({
    bg: 'primary-050',
    color: 'primary',
    '&:hover': {
      bg: 'primary-100',
      color: 'primary-500',
      textDecoration: 'none',
    },
    '&:active': { bg: 'primary-200' },
  }),
  variant({
    color: {
      neutral: {
        bg: 'neutral-500',
        color: 'white',
        '&:hover': { bg: 'neutral-400', color: 'white' },
        '&.focus-visible': {
          boxShadow: `0 0 0 0.125em ${colors['neutral-900']}`,
        },
        '&:active': { bg: 'neutral-900' },
      },
      blue: {
        bg: 'transparent',
        color: 'blue',
        '&:hover': { bg: 'blue-050', color: 'blue-800' },
        '&.focus-visible': { boxShadow: `0 0 0 0.125em ${colors['blue-200']}` },
        '&:active': { bg: 'blue-200' },
      },
      purple: {
        bg: 'transparent',
        color: 'purple',
        '&:hover': { bg: 'purple-050', color: 'purple-600' },
        '&.focus-visible': {
          boxShadow: `0 0 0 0.125em ${colors['purple-200']}`,
        },
        '&:active': { bg: 'purple-200' },
      },
      red: {
        bg: 'transparent',
        color: 'red',
        '&:hover': { bg: 'red-050', color: 'red-600' },
        '&.focus-visible': { boxShadow: `0 0 0 0.125em ${colors['red-200']}` },
        '&:active': { bg: 'red-200' },
      },
    },
  })
)

export { SecondaryButton }
export default Button
