import React from 'react'
import Box from 'components/box'

const Container = props => (
  <Box
    aria-hidden
    as="svg"
    flexShrink={0}
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    {...props}
  />
)

export default Container
