import React from 'react'
import Container from './container'

const Triangle = props => (
  <Container viewBox="0 0 27 24" {...props}>
    <path
      d="M3.281 0H23.72a3 3 0 0 1 2.577 4.536l-10.22 17.142a3 3 0 0 1-5.153 0L.704 4.536A3 3 0 0 1 3.281 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Container>
)

export default Triangle
