import React from 'react'
import Container from './container'

const Square = props => (
  <Container {...props}>
    <rect
      width={24}
      height={24}
      rx={3}
      fill="currentColor"
      fillRule="evenodd"
    />
  </Container>
)

export default Square
