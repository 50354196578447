import React from 'react'
import Container from './container'

const Circle = props => (
  <Container {...props}>
    <circle
      cx={508}
      cy={42}
      r={12}
      transform="translate(-496 -30)"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Container>
)

export default Circle
