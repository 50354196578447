import { Caret } from 'components/icon'
import { Flex } from 'components/box'
import Link from 'components/link'
import Products from './products'
import React, { useRef, useState } from 'react'
import transition from 'styles/transition'
import useOnClickOutside from 'use-onclickoutside'

const SiteLink = props => (
  <Flex as="li" flexGrow={[1, 0]} role="none" width={['100%', 'auto']}>
    <Link
      fontWeight="medium"
      justifyContent="flex-start"
      lineHeight={3}
      px={3}
      py={1}
      role="menuitem"
      width={['100%', 'auto']}
      {...props}
    />
  </Flex>
)

const SiteMenu = () => {
  const [isDisplayed, setIsDisplayed] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  useOnClickOutside(ref, () => isOpen && handleToggle(false))

  const handleToggle = flag => {
    setIsDisplayed(flag)
    setTimeout(() => setIsOpen(flag), 0)
  }

  return (
    <Flex as="ul" justifyContent="flex-end" ref={ref} pointerEvents="none">
      <Link
        aria-haspopup="menu"
        aria-expanded={isOpen}
        as="button"
        display={['inline-flex', 'none']}
        fontWeight="medium"
        lineHeight={3}
        onClick={() => handleToggle(!isOpen)}
        pointerEvents="initial"
        px={3}
        py={2}
      >
        Menu
        <Caret isOpen={isOpen} />
      </Link>
      <Flex
        alignItems={['flex-start', 'center']}
        as="ul"
        bg={['white', 'transparent']}
        borderRadius={[1, 0]}
        boxShadow={[4, 0]}
        display={isDisplayed ? 'flex' : ['none', 'flex']}
        flexDirection={['column', 'row']}
        opacity={isOpen ? 1 : [0, 1]}
        pointerEvents={isOpen ? 'initial' : ['none', 'initial']}
        position={['absolute', 'initial']}
        role="menu"
        style={{
          ...transition(
            isOpen ? [{ property: 'opacity' }, { property: 'transform' }] : []
          ),
          transformOrigin: 'top',
        }}
        transform={isOpen ? 'none' : ['translateY(-1rem)', 'none']}
        top={['100%', 'auto']}
      >
        <Products
          isDisplayed={isDisplayed}
          isOpen={isOpen}
          handleToggle={handleToggle}
        />
        <SiteLink my={['6px', 0]} py={['6px', 2]} to="/contact">
          Contact
        </SiteLink>
      </Flex>
    </Flex>
  )
}

export default SiteMenu
