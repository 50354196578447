import Box from 'components/box'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import variant from 'styles/variant'

const Text = styled(Box)(
  ({ measure }) =>
    css({
      maxWidth: measure,
    }),
  variant({
    truncate: {
      true: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  })
)

Text.propTypes = {
  measure: PropTypes.oneOf(['narrow', 'normal', 'wide']),
  truncate: PropTypes.bool,
}

const Caps = styled(Text)(
  css({
    fontWeight: 'semibold',
    letterSpacing: 'spaced',
    textTransform: 'uppercase',
  })
)

Caps.defaultProps = {
  color: 'neutral-300',
  fontSize: 7,
}

const Heading = styled(Text).attrs(({ level }) => ({
  as: `h${Math.max(level, 1)}`,
}))(
  ({ level }) =>
    css({
      fontFamily: 'heading',
      fontWeight: 'black',
      letterSpacing: level,
    }),
  variant({
    level: {
      0: {
        fontSize: [1, 1, 0],
      },
      1: {
        fontSize: [2, 2, 1],
      },
      2: {
        fontSize: [3, 3, 2],
      },
      3: {
        fontSize: [4, 4, 3],
      },
      4: {
        fontFamily: 'body',
        fontSize: 4,
        fontWeight: 'semibold',
      },
      5: {
        fontFamily: 'body',
        fontSize: 5,
        fontWeight: 'semibold',
      },
      6: {
        fontFamily: 'body',
        fontSize: 6,
        fontWeight: 'semibold',
        textTransform: 'uppercase',
      },
    },
  })
)

Heading.propTypes = {
  level: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]).isRequired,
}

Heading.defaultProps = {
  color: 'neutral-600',
  level: 2,
  lineHeight: 1,
  my: 0,
}

const Lead = styled(Text)({})

Lead.defaultProps = {
  as: 'p',
  color: 'neutral-400',
  fontSize: [4, 4, 3],
  letterSpacing: [4, 4, 3],
}

export { Caps, Heading, Lead }
export default Text
