import { colors } from 'styles/theme'
import { Link as GatsbyLink } from 'gatsby'
import css from '@styled-system/css'
import styled from 'styled-components'
import Text from 'components/text'
import transition from 'styles/transition'

const Link = styled(Text)(
  ({ hoverColor }) =>
    css({
      alignItems: 'center',
      bg: 'transparent',
      border: 0,
      cursor: 'pointer',

      '&:focus': { outline: 'none' },
      '&.focus-visible': { outline: `${colors['primary-200']} solid 2px` },
      '&:hover': {
        color: hoverColor,
        textDecoration: 'none',
      },
    }),
  transition({
    property: 'color',
  })
)

Link.defaultProps = {
  as: GatsbyLink,
  color: 'text',
  display: 'inline-flex',
  hoverColor: 'primary',
  justifyContent: 'center',
}

export default Link
