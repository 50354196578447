import { Caps } from 'components/text'
import { Tent } from 'components/icon'
import Box, { Content, Flex } from 'components/box'
import Link from 'components/link'
import React from 'react'

const FooterLink = props => (
  <li>
    <Link
      fontSize={[6, 5]}
      color="neutral-200"
      hoverColor="primary-200"
      mb={[0, 1]}
      mt={1}
      {...props}
    />
  </li>
)

const Footer = () => (
  <Box as="footer" bg="neutral-800" pb={5} pt={6} px={3}>
    <Content>
      <Flex flexDirection="row">
        <Flex
          flexGrow={1}
          flexDirection="row"
          justifyContent="space-between"
          mx="auto"
        >
          <Flex>
            <Box pr={[4, 5]} py={3}>
              <Caps color="neutral-400" mb={1}>
                Products
              </Caps>
              <ul>
                <FooterLink hoverColor="blue-400" to="/launcher">
                  Launcher
                </FooterLink>
                <FooterLink hoverColor="purple-300" to="/browser">
                  Browser
                </FooterLink>
                <FooterLink hoverColor="red-400" to="/timer">
                  Timer
                </FooterLink>
              </ul>
            </Box>
            <Flex>
              <Box pr={[4, 5]} py={3}>
                <ul>
                  <Caps color="neutral-400" mb={1}>
                    Links
                  </Caps>
                  <FooterLink to="/contact">Contact</FooterLink>
                  <FooterLink to="/terms">Terms</FooterLink>
                  <FooterLink to="/privacy">Privacy</FooterLink>
                </ul>
              </Box>
            </Flex>
          </Flex>
          <Box flexGrow="1" mt={-1} py={3} textAlign="right">
            <Link
              color="neutral-600"
              hoverColor="neutral-500"
              display="inline-block"
              fontSize={['4rem', '5rem']}
              fontWeight="black"
              letterSpacing="tight"
              lineHeight={0}
              to="/"
            >
              <Tent />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Content>
  </Box>
)

export default Footer
