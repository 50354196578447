import Box from 'components/box'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import transition from 'styles/transition'
import variant from 'styles/variant'

const Input = styled(Box)(
  css({
    appearance: 'none',
    border: 2,
    borderRadius: 1,
    borderColor: 'neutral-100',
    bg: 'white',
    fontSize: 'inherit',
    outline: 'none',
    p: 2,
    '&:hover': { borderColor: 'neutral-200' },
    '&:focus': { borderColor: 'primary-200', transition: 'none' },
  }),
  transition({
    property: 'border-color',
  }),
  variant({
    size: {
      small: {
        fontSize: 6,
        p: 1,
      },
      large: {
        fontSize: [5, 4],
        p: 3,
      },
    },
  }),
  variant({
    dark: {
      true: {
        bg: 'neutral-1000',
        borderColor: 'neutral-1000',
        color: 'white',
        '&:hover': { borderColor: 'neutral-1100' },
        '&:focus': {
          bg: 'neutral-1100',
          borderColor: 'primary-500',
          transition: 'none',
        },
      },
    },
  })
)

Input.defaultProps = {
  as: 'input',
  bg: 'neutral-025',
}

Input.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  dark: PropTypes.bool,
}

export default Input
