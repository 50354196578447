import { Flex } from 'components/box'
import React from 'react'

export const Page = props => <Flex {...props} />

Page.defaultProps = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: '100vw',
  minHeight: '100vh',
  overflow: 'hidden',
  position: 'relative',
}

export const Main = props => <Flex {...props} />

Main.defaultProps = {
  as: 'main',
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'center',
}
