import Box, { Content, Flex } from 'components/box'
import Button from 'components/button'
import Container from './container'
import Link from 'components/link'
import Menu from './menu'
import React, { useContext } from 'react'
import { AppContext } from 'store/app'
import { Tent } from 'components/icon'

const Header = () => {
  const { focusSubscriptionForm } = useContext(AppContext).appState

  return (
    <Container>
      <Box as="header" py={1}>
        <Content as="nav">
          <Flex
            as="ul"
            alignItems="center"
            justifyContent="space-between"
            mx={-3}
            position="relative"
          >
            <Box as="li" mx={3}>
              <Link
                color="primary"
                hoverColor="primary-300"
                lineHeight={3}
                to="/"
                fontSize="2rem"
                py={2}
              >
                <Tent />
              </Link>
            </Box>
            <Menu />
            <Box as="li">
              <Button
                as={Box}
                forwardAs="button"
                fontSize={5}
                lineHeight={3}
                mx={3}
                onClick={focusSubscriptionForm}
                px={24}
                py={12}
              >
                Join Beta
              </Button>
            </Box>
          </Flex>
        </Content>
      </Box>
    </Container>
  )
}

export default Header
