import Footer from 'components/footer'
import GlobalStyles from 'styles/global.css.js'
import Head from 'components/head'
import Header from 'components/header'
import PropTypes from 'prop-types'
import React from 'react'
import Subscribe from 'components/sections/subscribe'
import { Main, Page } from './layout.css'

const Layout = ({ children, pageTitle }) => (
  <Page>
    <GlobalStyles />
    <Head pageTitle={pageTitle} />
    <Header />
    <Main>{children}</Main>
    <Subscribe />
    <Footer />
  </Page>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
}

export default Layout
