import React from 'react'
import Container from './container'

const Tent = props => (
  <Container viewBox="0 0 32 24" {...props}>
    <path
      d="M16 0c1.015 0 12.591 7.622 13.028 8.245.437.623 2.397 12.65 1.972 13.163C30.575 21.922 17.334 24 16 24c-1.334 0-14.58-2.077-15-2.592-.42-.515 1.631-12.452 2.13-13.163C3.627 7.534 14.984 0 16 0zM6.795 12.982c-.116.329-.447 2.953-.995 7.873l3.805.745c.7-4.752 1.03-7.376.995-7.873-.054-.745-.995-1.591-1.688-1.727-.694-.136-1.944.49-2.117.982z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Container>
)

export default Tent
