import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import css from '@styled-system/css'

export default createGlobalStyle(
  normalize,
  css({
    html: {
      fontSize: 'base',
    },
    body: {
      bg: 'base',
      color: 'text',
      fontFamily: 'body',
      fontFeatureSettings: '"pnum"',
      fontSize: 5,
      fontVariantNumeric: 'proportional-nums',
      lineHeight: 2,
      MozOsxFontSmoothing: 'grayscale',
      textRendering: 'optimizeLegibility',
      WebkitFontFeatureSettings: '"pnum"',
      WebkitFontSmoothing: 'antialiased',
      WebkitTextSizeAdjust: '100%',
    },
    a: {
      display: 'inline-block',
      textDecoration: 'none',
      '&:hover': { textDecoration: 'underline' },
    },
    p: { marginBottom: 0, marginTop: 0 },
    h1: { fontSize: 1 },
    h2: { fontSize: 2 },
    h3: { fontSize: 3 },
    h4: { fontSize: 4 },
    h5: { fontSize: 5 },
    h6: { fontSize: 6 },
    ul: { listStyle: 'none', m: 0, p: 0 },
    li: { m: 0, p: 0 },
  })
)
