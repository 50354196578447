import Triangle from './triangle'
import css from '@styled-system/css'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import transition from 'styles/transition'

const Caret = styled(Triangle)(
  ({ isOpen }) =>
    css({
      opacity: 0.5,
      ml: 2,
      mt: '0.06125rem',
      fontSize: '0.5rem',
      transform: isOpen ? 'rotate(180deg)' : 'none',
    }),
  transition({ property: 'transform', duration: '125ms' })
)

Caret.propTypes = {
  isOpen: PropTypes.bool,
}

export default Caret
